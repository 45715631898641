import router from '../router'
import axios from 'axios'
import store from '../store'
import {Toast} from "vant";


//  开发时使用
// export const BASE_URL = 'api/';
// 生产配置
// export const BASE_URL = 'https://pro.xinian727.com/api/';
export const BASE_URL = 'https://testapi.xinian727.cn/api/';

axios.defaults.baseURL = BASE_URL;
axios.defaults.timeout = 30000;


axios.interceptors.request.use(
  config => {
    // if (bus.state.token) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
    // config.headers.Authorization = `${store.state.token}`;
    config.headers['X-XN-Token'] = `${store.state.token}`;
    // }
      console.info("request",config.url)
      // if (
      //     config.url.indexOf("ticketValidateTest")<0&&
      //     config.url.indexOf("login/login")<0
      // ){
          Toast.loading({duration: 0, forbidClick: true,message: "加载中..."});
      // }else {
      //     Toast.clear()
      // }
    return config;
  },
  err => {
    return Promise.reject(err);
  });

// http response 拦截器
axios.interceptors.response.use(
  response => {
    console.info("http response:", response)
    Toast.clear()
    let d = response.data
    if (d.status == 10) {
      const {
        protocol,
        host,
        pathname
      } = window.location
      const domain = protocol + "//" + host
      setTimeout(() => {
        window.location.href = domain +pathname + "auth"
      }, 200);
    }else if (d.status == 406) {
      return {'notRegister':true}
    }else if (d.status == 1) {
      console.info("返回值状态码为1，返回值为：",d)
      Toast.fail(d.msg)
    }
    else {
      return d.data;
    }
  },
  error => {
    Toast.clear()
    if (error.response) {
      switch (error.response.status) {
        case 10:
          // 返回 10 清除token信息并跳转到登录页面
          store.state.token = undefined;
          if (router.currentRoute.fullPath.includes('register')) {
            // skip
          } else if (!router.currentRoute.fullPath.includes('auth')) {
            router.replace({
              path: 'auth',
              query: {
                redirect: router.currentRoute.fullPath
              }
            })
          }
      }
    }
    return Promise.reject(error.response.data) // 返回接口返回的错误信息
  });
export default axios;
